.large-hover-card{
    margin-top: 1.5rem;
    padding: 1rem;
    width: 300px;
    height: 300px;
    background-color: rgb(243, 241, 241);
    border-radius: 1rem;
    border: 2px solid #c6d92f
}
.large-hover-card .inner-wrap {
    margin-top: 10px;
    transition: margin-top .6s ease;
}
.inner-wrap h2{
    text-align: center;
    color: black;
}
.card-icon{
    text-align: center;
}

.large-hover-card:hover .inner-wrap .card-icon{
    visibility: hidden;
}
.large-hover-card:hover .inner-wrap h2{
    text-align: left;
}
.large-hover-card:hover .inner-wrap {
    margin-top: -6.5rem ;
}
.large-hover-card .inner-wrap .summary-wrap {
    max-height: 0;
    opacity: 0;
    transition: max-height .6s ease,opacity .6s ease,visibility .6s ease;
    visibility: hidden
}

.large-hover-card:hover .inner-wrap .summary-wrap {
    max-height: 320px;
    opacity: 1;
    visibility: visible
}
.large-hover-cards .swiper-slide {
    height: auto;
    max-width: 400px;
    width: 100%
}

.large-hover-cards .swiper-buttons .swiper-button-disabled {
    opacity: .5
}
.whatsapp_float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    display: flex;
    justify-content: center;
    background-color: #c6d92f;
    color: #FFF;
    border-radius: 50px;
    align-items: center;
    text-align: center;
    font-size: 16px;
    z-index: 10000;
    cursor: pointer;
  }
  
  .whatsapp-icon {
    /* margin-top: 16px; */
    font-size: 30px;
    color: #fff;
    margin-left: -0.4rem;
  
  }